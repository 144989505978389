import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import fetch from 'cross-fetch';

const httpLink = createHttpLink({
  uri: 'https://graphql.mindsett.dev/v1/graphql',
  fetch
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});
